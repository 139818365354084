import { useContext, useEffect, useState } from "react";
import api from "./common/axios";
import Login from "./Login";
import App from "./App";


export function Authorization() {
    const [auth, set_auth] = useState(false);
    
    useEffect(()=>{
        api().get("/login", {headers:{Authorization: localStorage.getItem("token")}})
        .then((res) => {
            set_auth(true)
        })
        .catch((err) => {
            if (err.response.status == 401) {
                localStorage.removeItem("token")
                set_auth(false)
            }
        })
    },[])



    if (auth) {
        return <App/>
    } else {
        return <Login />
    }
}