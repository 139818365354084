import React, { useState } from 'react';
import './App.css';
import api from './common/axios';
import { Button, Stack, TextField } from '@mui/material';

function Login() {
  
  const [password, set_password] = useState("");

  function password_change(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    set_password(e.target.value)
  }

  function login(){
    api().get("/login", {headers:{Authorization: password}})
    .then((res)=>{
        localStorage.setItem("token", res.data)
        window.location.reload()
    })
    .catch((e)=>{})
  }

  // 비밀번호 엔터 프레스
  function password_key_press(e: React.KeyboardEvent<HTMLInputElement>){
    if(e.keyCode == 13){
      login()
    }
  }

  return (
    <>
        <div style={{height:"100%", minHeight:"100vh", display:"flex"}}>
            <Stack direction={"row"} style={{margin:"auto"}}>
            <TextField id="outlined-basic" label="비밀번호" variant="outlined" onChange={password_change} onKeyDown={password_key_press}/>
            <Button onClick={login}>로그인</Button>
            </Stack>
        </div>
    </>
  );
}

export default Login;
