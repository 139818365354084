import { useEffect, useState } from 'react';
import './App.css';
import { PostData } from './dto';
import { useSearchParams } from 'react-router-dom';
import api from './common/axios';
import Pagination from "react-js-pagination";
import './scss/pagination.scss'
import { Button, IconButton, Input, Stack, TextField } from '@mui/material';
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";


const OPTIONTYPE = Object.freeze({
  CATEGORY: "category",
  SORT: "sort",
  PAGE: "page",
  VIEW: "view",
  LIKE: "like",
  SEARCH: "search",
})


function App() {
  
  const [category, set_category] = useState<string[]>([]);
  const [post_data, set_post_data] = useState<PostData[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [option_category, set_option_category] = useState(searchParams.get(OPTIONTYPE.CATEGORY) ? searchParams.get(OPTIONTYPE.CATEGORY) : "all");
  const [option_sort, set_option_sort] = useState(searchParams.get(OPTIONTYPE.SORT) ? searchParams.get(OPTIONTYPE.SORT) : "N");
  const [option_page, set_option_page] = useState(searchParams.get(OPTIONTYPE.PAGE) ? searchParams.get(OPTIONTYPE.PAGE) : "1");
  const [option_viewcut, set_option_viewcut] = useState(searchParams.get(OPTIONTYPE.VIEW) ? searchParams.get(OPTIONTYPE.VIEW) : "0");
  const [option_likecut, set_option_likecut] = useState(searchParams.get(OPTIONTYPE.LIKE) ? searchParams.get(OPTIONTYPE.LIKE) : "0");
  const [option_search_keyword, set_option_search_keyword] = useState(searchParams.get(OPTIONTYPE.SEARCH) ? searchParams.get(OPTIONTYPE.SEARCH) : "");
  
  const [total_items, set_total_items] = useState(0);

  const [is_loding, set_is_loding] = useState(true);


  function option_change(type:string | null = null, val: string | null = null) {
    let url = "/post?"

    let options = {
      category: option_category,
      sort: option_sort,
      page: option_page,
      view: option_viewcut,
      like: option_likecut,
      search: option_search_keyword
    }

    if (type && val) {
      Object.values(OPTIONTYPE).forEach((ot)=>{
        if (ot == type) {
          options[ot] = val
        }
      })
      if (type != OPTIONTYPE.PAGE) options[OPTIONTYPE.PAGE] = "1"
    }

    if (options.page != "1") url += `&page=${options.page}`;
    if (options.category != "all") url += `&category=${options.category}`;
    if (options.sort != "N") url += `&sort=${options.sort}`;
    if (options.view != "0") url += `&view=${options.view}`;
    if (options.like != "0") url += `&like=${options.like}`;
    if (options.search != "") url += `&search=${options.search}`;
    window.location.href = url
  }

  // 테마
  const isUserColorTheme = localStorage.getItem("color-theme");
  const isOsColorTheme = window.matchMedia("(prefers-color-scheme: dark)")
    .matches
    ? "dark"
    : "light";
  const getUserTheme = () =>
    isUserColorTheme ? isUserColorTheme : isOsColorTheme;

  function theme_change() {
    if (localStorage.getItem("color-theme") === "dark") {
      localStorage.setItem("color-theme", "light");
      document.documentElement.setAttribute("color-theme", "light");
    } else {
      localStorage.setItem("color-theme", "dark");
      document.documentElement.setAttribute("color-theme", "dark");
    }
  }

  function view_change_handler(e: React.ChangeEvent<HTMLInputElement>) {
    set_option_viewcut(e.target.value)
  }

  function like_change_handler(e: React.ChangeEvent<HTMLInputElement>) {
    set_option_likecut(e.target.value)
  }

  function keyDown_handler(e:KeyboardEvent) {
    setKeyDown(e.key)
  }
  const [keydown, setKeyDown] = useState<string | null>(null);

  useEffect(() => {
    if(keydown === "ArrowRight") {
      const maxpage = Math.ceil((total_items/30))
      const page = Number(option_page)
      if (total_items == 0) {
        setKeyDown(null)
        return
      }
      if (page >= maxpage) {
        // option_change(OPTIONTYPE.PAGE, maxpage.toString())
        setKeyDown(null)
        return
      } else {
        option_change(OPTIONTYPE.PAGE, (page + 1).toString())
      }
    };
    if(keydown === "ArrowLeft") {
      const page = Number(option_page)
      if (page > 2) {
        option_change(OPTIONTYPE.PAGE, (page - 1).toString())
      }
    };
  }, [keydown]);

  useEffect(()=>{
    window.addEventListener("keydown", keyDown_handler);

    if (getUserTheme() === "dark") {
      localStorage.setItem("color-theme", "dark");
      document.documentElement.setAttribute("color-theme", "dark");
    } else {
      localStorage.setItem("color-theme", "light");
      document.documentElement.setAttribute("color-theme", "light");
    }

    api().get(`post?category=${option_category}&sort=${option_sort}&page=${option_page}&view=${option_viewcut}&like=${option_likecut}&search=${option_search_keyword}`)
    .then((res)=>{
      set_category(res.data.category)
      set_post_data(res.data.postData)
      set_is_loding(false)
    })
    api().get(`count/post?category=${option_category}&sort=${option_sort}&page=${option_page}&view=${option_viewcut}&like=${option_likecut}&search=${option_search_keyword}`)
    .then((res)=>{
      const maxpage = Math.ceil((res.data/30))
      const page = Number(option_page)
      if (page > maxpage) option_change(OPTIONTYPE.PAGE, maxpage.toString())
      set_total_items(res.data)
    })

  },[])

  return (
    <div className='main'>

      {/* 헤더 */}
      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid var(--divider-color)", height:64, marginBottom:10, padding:"0px 1%"}}>
        <div>편하게 보기위한!</div>
        <Stack direction={"row"} spacing={1}>
          {/* 테마변경 */}
          <button onClick={theme_change} style={{padding:8, height:44}}>테마변경</button>
          {/* 검색 */}
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              background: "var(--p-bg)",
              padding:0,
              margin:0,
            }}
          >
            <Input
              sx={{ ml: 1, flex: 1, color: "var(--text_color)"}}
              disableUnderline
              placeholder="검색"
              value={option_search_keyword}
              onChange={(e)=>{set_option_search_keyword(e.target.value)}}
              onKeyDown={(e)=>{if(e.keyCode == 13) option_change(OPTIONTYPE.SEARCH, option_search_keyword)}}
            />
            <IconButton
              type="button"
              sx={{p:"10px", boxShadow:"none"}}
              aria-label="search"
              onClick={()=>{option_change(OPTIONTYPE.SEARCH, option_search_keyword)}}
            >
              <SearchIcon sx={{ filter: "var(--icon_invert)" }} />
            </IconButton>
          </Paper>

        </Stack>
      </div>

      {/* 바디 */}
      <div style={{padding: "0px 1%"}}>
        {/* 정렬 */}
        <div className='sort-container'>
          <button onClick={()=>{option_change(OPTIONTYPE.SORT, "N")}} style={{color:option_sort == "N"? "red" : ""}}>최신순</button>
          <button onClick={()=>{option_change(OPTIONTYPE.SORT, "L")}} style={{color:option_sort == "L"? "red" : ""}}>좋아요수순</button>
          <button onClick={()=>{option_change(OPTIONTYPE.SORT, "V")}} style={{color:option_sort == "V"? "red" : ""}}>조회수순</button>
        </div>


        <div className='option-container'>
          <div className='option'>
            <TextField type='number' label="조회수 필터링" sx={{flexGrow:"1", label:{color:"var(--text_color)"}, input:{color:"var(--text_color)"}}} value={option_viewcut == "0" ? null : option_viewcut} onChange={view_change_handler} variant="standard"/>
            <Button sx={{boxShadow:"none"}} onClick={()=>{option_change()}}>적용</Button>
          </div>
          <div className='option'>
            <TextField type='number' label="좋아요수 필터링" sx={{flexGrow:"1", label:{color:"var(--text_color)"}, input:{color:"var(--text_color)"}}} value={option_likecut == "0" ? null : option_likecut} onChange={like_change_handler} variant="standard"/>
            <Button sx={{boxShadow:"none"}} onClick={()=>{option_change()}}>적용</Button>
          </div>
        </div>



        <div className='category-grid-container'>
          {/* 카테고리 - 전체보기 */}
          <button onClick={()=>{option_change(OPTIONTYPE.CATEGORY, "all")}}
            style={{color:option_category == "all" ? "red" : ""}}>전체보기</button>
          {/* 카테고리 */}
          { category.map((cate, idx) => {
            return <button key={idx} 
                onClick={()=>(option_change(OPTIONTYPE.CATEGORY, cate))}
                style={{color:cate == option_category ? "red" : ""}}
                >
                  {cate}
                </button>
          })}
        </div>

        { is_loding &&
          <div style={{fontSize:"50px", textAlign:"center"}}>로딩중</div>
        }

        <div className='post-grid-container'>
          {/* 게시글 */}
          { post_data &&
            post_data.map((post, idx) => {
              return <a key={idx} className='post-grid-item' href={post.aurl} target='_blank'>
                <img src={post.imgurl} />
                <Stack direction={"column"}
                spacing={0.5}
                sx={{
                  justifyContent: "space-between",
                  flexGrow:"1",
                }}>
                  <div style={{fontSize: "18px", fontWeight:"bold"}}>{post.title} - {post.cate}</div>
                  <Stack direction={"column"} spacing={0.2}>
                    <Stack direction={"row"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "15px"
                    }}>
                      <Stack direction={"row"} spacing={0.5} sx={{alignItems: "center",}}>
                        <img className='icon' src={`${process.env.REACT_APP_SERVER_APP_URL}/img/view.png`} style={{filter:"var(--icon_invert)"}} />
                        {/* <img className='icon' src={`http://localhost:3001/img/view.png`} style={{filter:"var(--icon_invert)"}} /> */}
                        <div>{post.view.toLocaleString()}</div>
                      </Stack>
                      <Stack direction={"row"} spacing={0.5} sx={{alignItems: "center",}}>
                        <img className='icon' src={`${process.env.REACT_APP_SERVER_APP_URL}/img/like.png`} style={{filter:"var(--icon_invert)"}} />
                        {/* <img className='icon' src={`http://localhost:3001/img/like.png`} style={{filter:"var(--icon_invert)", height: 13}} /> */}
                        <div>{post.like.toLocaleString()}</div>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "12px",
                      color: "#bbb8bf",
                    }}>
                      <div>{post.postdur}</div>
                      <div>{post.postup}</div>
                    </Stack>
                  </Stack>
                </Stack>
              </a>
            })
          }
        </div>
      </div>

      <Pagination
        activePage={Number(option_page)}
        itemsCountPerPage={30}
        totalItemsCount={total_items}
        pageRangeDisplayed={5}
        prevPageText={"<"}
        nextPageText={">"}
        onChange={(pg)=>{option_change(OPTIONTYPE.PAGE, pg.toString())}}
      />
    </div>
  );
}

export default App;
